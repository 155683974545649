import { useEffect, useState } from 'react';
import './App.css';
import HeatMap from '@uiw/react-heat-map';

function App() {
  const [calData, setCaldata] = useState([])
  const getData = async ()=>{
    const data = await fetch('batches.json');
    const json = await data.json();

    const filteredData = json
    .filter(v => v.brewDate > 1640991600000)
    .filter(v => v.status !== 'Planning')
    
    const calendarValues = filteredData.map(v => ({
      date: new Date(v.brewDate).toString(),
      count: v.estimatedColor
    }))

    console.log(calendarValues);
    setCaldata(calendarValues);
  }
  useEffect(()=>{
    if(calData.length < 5) {
      getData();
    }
  },[calData])

  console.log(calData);
  return (
    <div className="App">
      <header className="App-header">
        {!!calData.length &&
      <HeatMap
      width={800}
      startDate={new Date('2021-12-31')}
      // endDate={new Date('2022-12-31')}
      value={calData}
      />
      }
      </header>
    </div>
  );
}

export default App;
